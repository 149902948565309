@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
.translate-right {
  transform: translateX(100%);
  opacity: 0.1;
}

.translate-left {
  transform: translateX(-100%);
  opacity: 0.1;
}

.translate-up {
  transform: translateY(200px);
  opacity: 0.1;
}

.header-wrapper {
  width: 100%;
  height: 9rem;
  z-index: 10;
  background-color: var(--main-bg);
  position: fixed;
  left: 0;
  right: auto;
  top: 0;
  bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.header {
  width: 1180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 5rem;
  font-family: var(--ff-secondary);
}
.header i {
  font-size: var(--fs-md);
  color: var(--ff-secondary);
  cursor: pointer;
  display: none;
}

.header .logo p {
  font-size: 3rem;
  font-family: var(--ff-primary);
  color: var(--primary-cl);
}

.header .nav-wrapper ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 8rem;
  list-style: none;
}
.header .nav-wrapper ul li a {
  text-decoration: none;
  font-size: var(--fs-sm);
  color: var(--text-cl);
  transition: 0.2s;
}
.header .nav-wrapper ul li a:hover {
  color: var(--primary-cl);
}
.header .nav-wrapper ul li:last-child {
  border: 1px solid var(--btn-bg);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: var(--btn-bg);
  transition: 0.5s;
  box-shadow: 0px 6px 17px 0px var(--shadow-cl);
}
.header .nav-wrapper ul li:last-child a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.075rem;
}
.header .nav-wrapper ul li:last-child:hover {
  background-color: transparent;
}
.header .nav-wrapper ul li:last-child:hover a {
  color: var(--btn-bg);
  font-weight: 800;
}
.header .nav-wrapper ul li:last-child a {
  font-size: 1.6rem;
}

.header .mobile-nav {
  background-color: rgba(32, 32, 32, 0.952);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: auto;
  top: 0;
  bottom: 0;
  display: none;
}
.header .mobile-nav i {
  position: absolute;
  left: auto;
  right: auto;
  top: 0;
  bottom: 0;
  margin: 4rem;
  font-size: var(--fs-md);
  cursor: pointer;
  color: white;
}
.header .mobile-nav .mobile-link ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  list-style: none;
}
.header .mobile-nav .mobile-link li a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.4rem;
  font-size: var(--fs-md);
}

@media screen and (max-width: 1440px) {
  .header {
    width: 80%;
  }
}
@media screen and (max-width: 880px) {
  .header {
    width: 95%;
  }
}
@media screen and (max-width: 650px) {
  .header .logo p {
    font-size: 4rem;
  }
  .header .nav-wrapper {
    display: none;
  }
  .header i {
    display: flex;
  }
}
.wrapper-1 {
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 12rem;
  margin-top: 12rem;
}
.wrapper-1 .left-shadow {
  position: absolute;
  left: 0;
  right: auto;
  top: 10rem;
  bottom: auto;
  height: 30rem;
  box-shadow: 0px 0px 107px 25px var(--shadow-cl);
}

.hero-banner-wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.left-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 2.6rem;
  letter-spacing: 0.1rem;
}
.left-section p:first-child, .left-section p:nth-child(2) {
  font-weight: 600;
  font-family: var(--ff-primary);
  color: #637471;
  font-size: var(--fs-lg);
}
.left-section p:nth-child(2) {
  color: var(--primary-cl);
  font-size: var(--fs-xl);
}
.left-section p:nth-child(3) {
  width: 80%;
  line-height: var(--lh-md);
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
}
.left-section .hero-btn-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 2rem;
}
.left-section .hero-btn-wrapper button {
  border: 1px solid var(--btn-bg);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: var(--btn-bg);
  transition: 0.5s;
  font-family: var(--ff-secondary);
  margin-top: 2rem;
  box-shadow: 0px 6px 17px 0px var(--shadow-cl);
}
.left-section .hero-btn-wrapper button a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.075rem;
}
.left-section .hero-btn-wrapper button:hover {
  background-color: transparent;
}
.left-section .hero-btn-wrapper button:hover a {
  color: var(--btn-bg);
  font-weight: 800;
}

.right-section {
  transition: all 0.5s ease-in-out;
}
.right-section img {
  width: 45rem;
}
.right-section .hero-icons {
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row;
  gap: 2rem;
}
.right-section .hero-icons a {
  font-size: 3rem;
  color: var(--primary-cl);
}

@media screen and (max-width: 1050px) {
  .right-section img {
    width: 35rem;
  }
}
@media screen and (max-width: 880px) {
  .hero-banner-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 760px) {
  .hero-banner-wrapper {
    flex-direction: column;
    gap: 9rem;
    margin-top: 15rem;
  }
  .hero-banner-wrapper .left-section {
    align-items: center;
    text-align: center;
  }
}
.container-2 {
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 12rem;
  transition: all 0.5s ease-in-out;
}

.about-wrapper {
  width: 90%;
  height: 65rem;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(153, 151, 151, 0.205);
  background-color: rgba(245, 246, 246, 0.5607843137);
}
.about-wrapper .left-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 3rem;
  margin-left: 6rem;
}
.about-wrapper .left-section img {
  width: 45rem;
}
.about-wrapper .right-section {
  display: flex;
  height: 100%;
  padding: 1rem;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.about-wrapper .right-section p:nth-child(1) {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
  margin-top: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  position: relative;
  left: 0;
}
.about-wrapper .right-section-2 {
  background-color: rgba(155, 155, 155, 0.288);
  box-shadow: 0px 0px 15px 0px rgba(131, 131, 145, 0.288);
  height: 100%;
  font-size: var(--fs-xs);
  width: 80%;
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
  letter-spacing: 0.025rem;
  line-height: var(--lh-lg);
  font-weight: 500;
  padding: 2rem;
  border-radius: 0.7rem;
  border-bottom-left-radius: 0rem;
  border-top-left-radius: 0rem;
  position: relative;
  overflow: hidden;
}
.about-wrapper .right-section-2 .top-shadow,
.about-wrapper .right-section-2 .bottom-shadow {
  position: absolute;
  left: 0;
  box-shadow: 0px 0px 107px 39px var(--shadow-cl);
}
.about-wrapper .right-section-2 .bottom-shadow {
  right: 0;
  left: auto;
  bottom: 0;
}

@media screen and (max-width: 1080px) {
  .about-wrapper .left-section {
    margin-left: 2rem;
  }
  .about-wrapper .left-section img {
    width: 35rem;
  }
}
@media screen and (max-width: 918px) {
  .about-wrapper .right-section-2 {
    width: 95%;
  }
}
@media screen and (max-width: 880px) {
  .about-wrapper {
    height: 56rem;
    width: 95%;
  }
}
@media screen and (max-width: 760px) {
  .about-wrapper {
    flex-direction: column;
    width: 95%;
    height: auto;
    flex-direction: column-reverse;
    padding: 1rem;
  }
  .about-wrapper .left-section {
    margin-left: 0rem;
    margin-top: 5rem;
  }
  .about-wrapper .right-section {
    width: 100%;
    text-align: center;
  }
}
.container-3 {
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 12rem;
  transition: all 1s ease-in-out;
}
.container-3 .right-shadow {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  height: 4rem;
  width: 0rem;
  box-shadow: 0px 0px 144px 28px var(--shadow-cl);
}

.skill-wrapper {
  background-color: rgba(242, 244, 245, 0.7411764706);
  padding: 6rem 4rem;
  overflow: hidden;
  width: 90%;
  margin: auto;
  position: relative;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(212, 212, 212, 0.288);
  gap: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.skill-wrapper .left-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.skill-wrapper .left-section .skill-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 4px inset;
  border-radius: 50%;
  overflow: hidden;
}
.skill-wrapper .left-section .skill-icon::after {
  position: absolute;
  content: "";
  background-image: conic-gradient(#f0eeee 100deg, transparent 180deg);
  height: 100%;
  width: 140%;
  border-radius: 50%;
  transform: rotate(0deg);
  animation: border-rotate 2s linear infinite;
}
.skill-wrapper .left-section .skill-icon::before {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  border-radius: 50%;
  content: "JS";
  height: 90%;
  width: 90%;
  background-color: var(--main-bg);
  box-shadow: 0px 0px 4px var(--shadow-color);
  text-align: center;
  z-index: 2;
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
}
.skill-wrapper .left-section .skill-icon:nth-child(2)::before {
  content: "React";
  animation-delay: 0.4s;
}
.skill-wrapper .left-section .skill-icon:nth-child(3)::before {
  content: "Tailwind";
  animation-delay: 0.7s;
}
.skill-wrapper .left-section .skill-icon:nth-child(4)::before {
  content: "TS";
  font-size: 1.6rem;
  animation-delay: 0.8s;
}
.skill-wrapper .left-section .skill-icon:nth-child(5)::before {
  content: "Git";
}
.skill-wrapper .left-section .skill-icon:nth-child(6)::before {
  content: "Scss";
  animation-delay: 0.1s;
}

@keyframes border-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.skill-wrapper .right-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 80%;
}
.skill-wrapper .right-section .heading {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
}
.skill-wrapper .right-section .skills-info {
  line-height: var(--lh-lg);
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
}

.skill-wrapper .left-shadow {
  position: absolute;
  left: 0;
  right: auto;
  top: 3rem;
  bottom: auto;
  width: 0rem;
  height: 30rem;
  box-shadow: 0px 0px 107px 27px var(--shadow-cl);
}

@media screen and (max-width: 880px) {
  .skill-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 760px) {
  .skill-wrapper {
    flex-direction: column-reverse;
    gap: 9rem;
    padding: 6rem 2rem;
  }
  .skill-wrapper .left-section {
    width: 70%;
    gap: 5rem;
  }
  .skill-wrapper .right-section {
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .skill-wrapper .left-section {
    width: 100%;
    gap: 5rem;
  }
  .skill-wrapper .left-section .skill-icon {
    width: 70px;
    height: 70px;
  }
  .skill-wrapper .right-section {
    align-items: center;
    text-align: center;
  }
}
.container-4 {
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 12rem;
  background-color: var(--primary-cl);
  transition: all 0.5s ease-in-out;
}

.process-wrapper {
  padding: 4rem 0rem;
  width: 90%;
  margin: auto;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6rem;
}
.process-wrapper .top-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.process-wrapper .top-section p:first-child {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
  color: white;
}
.process-wrapper .top-section p:last-child {
  width: 80%;
  text-align: center;
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
  color: white;
  line-height: var(--lh-md);
}

.process-wrapper .bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 60%;
}
.process-wrapper .bottom-section .image {
  position: relative;
  z-index: 2;
}
.process-wrapper .bottom-section .image img {
  width: 30rem;
}
.process-wrapper .image:nth-child(1):before {
  position: absolute;
  left: auto;
  right: -242px;
  top: 50%;
  bottom: auto;
  content: "";
  width: 38rem;
  height: 0rem;
  border: 2px dashed white;
  z-index: -1;
}
.process-wrapper .image:nth-child(2):before,
.process-wrapper .image:nth-child(2):after {
  position: absolute;
  left: -1rem;
  right: auto;
  top: 49%;
  bottom: auto;
  content: "";
  width: 1rem;
  height: 0rem;
  transform: rotate(226deg);
  border: 2px solid white;
  z-index: -1;
}
.process-wrapper .image:nth-child(2):after {
  transform: rotate(309deg);
  top: 52%;
}

@media screen and (max-width: 1050px) {
  .process-wrapper .bottom-section {
    width: 80%;
  }
}
@media screen and (max-width: 880px) {
  .process-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 760px) {
  .process-wrapper .bottom-section .image img {
    width: 25rem;
  }
}
@media screen and (max-width: 600px) {
  .process-wrapper .bottom-section .image:nth-child(1):before {
    right: -111px;
    width: 18rem;
  }
  .process-wrapper .bottom-section .image img {
    width: 16rem;
  }
}
.container-5 {
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 12rem;
  transition: all 0.5s ease-in-out;
}

.work-wrapper {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8rem;
}
.work-wrapper .top-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.work-wrapper .top-section p:first-child {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
}
.work-wrapper .top-section p:last-child {
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
}

.work-wrapper .bottom-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8rem;
  width: 100%;
}
.work-wrapper .bottom-section .project {
  transition: transform 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(170, 169, 169, 0.753);
  border-radius: 1rem;
}
.work-wrapper .bottom-section .project .project-images {
  width: inherit;
  position: relative;
  margin: 1rem;
  z-index: 2;
}
.work-wrapper .bottom-section .project .project-images img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 0.3rem;
}
.work-wrapper .bottom-section .project .project-images .project-img2 {
  position: absolute;
  left: 22rem;
  top: 9rem;
}
.work-wrapper .bottom-section .project .project-info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}
.work-wrapper .bottom-section .project .project-info p:first-child {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
  font-size: 4rem;
}
.work-wrapper .bottom-section .project .project-info p:nth-child(2) {
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
  text-align: inherit;
  line-height: var(--lh-md);
}
.work-wrapper .bottom-section .project .project-info .project-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 3rem;
  margin-top: 2rem;
}
.work-wrapper .bottom-section .project .project-info .button {
  border: 1px solid var(--btn-bg);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: var(--btn-bg);
  transition: 0.5s;
  font-size: var(--fs-xs);
  font-family: var(--ff-secondary);
}
.work-wrapper .bottom-section .project .project-info .button a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.075rem;
}
.work-wrapper .bottom-section .project .project-info .button:hover {
  background-color: transparent;
}
.work-wrapper .bottom-section .project .project-info .button:hover a {
  color: var(--btn-bg);
  font-weight: 800;
}
.work-wrapper .bottom-section .project .project-info .button i {
  margin: 0rem 1rem;
}

.work-wrapper .bottom-section .project:first-child::after,
.work-wrapper .bottom-section .project:first-child::before,
.work-wrapper .bottom-section .project:nth-child(2)::before,
.work-wrapper .bottom-section .project:nth-child(2)::after,
.work-wrapper .bottom-section .project:nth-child(3)::before,
.work-wrapper .bottom-section .project:nth-child(3)::after,
.work-wrapper .bottom-section .project:nth-child(4)::before,
.work-wrapper .bottom-section .project:nth-child(4)::after {
  position: absolute;
  left: -4rem;
  right: auto;
  top: -53px;
  bottom: auto;
  content: "";
  width: 56rem;
  height: 131%;
  background-image: url("../images//tmSS.png");
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0.3;
  transform: rotate(346deg);
}
.work-wrapper .bottom-section .project:first-child::before,
.work-wrapper .bottom-section .project:nth-child(2)::before,
.work-wrapper .bottom-section .project:nth-child(3)::before,
.work-wrapper .bottom-section .project:nth-child(4)::before {
  background-image: url("../images//tmSS.png");
  right: -127px;
  left: auto;
  transform: rotate(9deg);
}
.work-wrapper .bottom-section .project:nth-child(2)::before {
  background-image: url("../images//sgSS.png");
}
.work-wrapper .bottom-section .project:nth-child(3)::before {
  background-image: url("../images//ytSS.png");
}
.work-wrapper .bottom-section .project:nth-child(4)::before {
  background-image: url("../images//ex1.png");
}

@media screen and (max-width: 1250px) {
  .work-wrapper .bottom-section .project .project-info {
    width: 90%;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 1050px) {
  .work-wrapper .bottom-section .project .project-info {
    width: 100%;
  }
}
@media screen and (max-width: 880px) {
  .work-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 760px) {
  .work-wrapper .bottom-section .project {
    flex-direction: column;
    padding: 3rem 0rem;
    gap: 3rem;
  }
  .work-wrapper .bottom-section .project .project-info {
    align-items: center;
    text-align: center;
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .work-wrapper .bottom-section .project:first-child::before,
  .work-wrapper .bottom-section .project:nth-child(2)::before,
  .work-wrapper .bottom-section .project:nth-child(3)::before,
  .work-wrapper .bottom-section .project:nth-child(4)::before {
    display: none;
  }
  .work-wrapper .bottom-section .project .project-info {
    align-items: center;
    text-align: center;
    width: 80%;
  }
}
.container-6 {
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 12rem;
  margin-bottom: 10rem;
  transition: all 0.5s ease-in-out;
}

.contact-wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 5rem;
}
.contact-wrapper .top-section {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
}
.contact-wrapper .email-confirmation-message {
  font-size: 2.5rem;
  font-family: var(--ff-secondary);
  text-align: center;
  line-height: var(--lh-lg);
  color: var(--primary-cl);
}

.contact-wrapper .middle-section {
  width: 100%;
}
.contact-wrapper .middle-section form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 3rem;
}
.contact-wrapper .middle-section form input,
.contact-wrapper .middle-section form textarea {
  border: 1px solid var(--primary-cl);
  border: none;
  height: 6rem;
  font-size: 2rem;
  padding-top: 1rem;
  padding-left: 1.5rem;
  width: 50rem;
  background: transparent;
  border-radius: 0.5rem;
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  box-shadow: 0px 0px 10px 0px rgba(58, 55, 55, 0.164);
  transition: 0.5s;
}
.contact-wrapper .middle-section form input:hover,
.contact-wrapper .middle-section form textarea:hover {
  box-shadow: 0px 0px 10px 0px rgba(129, 128, 128, 0.849);
}
.contact-wrapper .middle-section form input:focus,
.contact-wrapper .middle-section form textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--primary-cl);
}
.contact-wrapper .middle-section form textarea {
  height: 20rem;
  resize: none;
}
.contact-wrapper .middle-section form button {
  background-color: var(--primary-cl);
  border: 2px solid var(--primary-cl);
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 2rem;
  color: white;
  padding: 1rem 4rem;
  letter-spacing: 0.1rem;
  transition: 0.5s;
  margin-top: 1rem;
}
.contact-wrapper .middle-section form button:hover {
  background-color: transparent;
  color: var(--primary-cl);
}

.contact-wrapper .bottom-section {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.contact-wrapper .bottom-section .gmail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
}
.contact-wrapper .bottom-section .gmail p {
  font-size: var(--fs-sm);
}
.contact-wrapper .bottom-section .contact-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 3rem;
}
.contact-wrapper .bottom-section i {
  font-size: 3rem;
  color: var(--primary-cl);
  text-shadow: 0px 0px 7px rgb(151, 151, 151);
  transition: 0.5s ease-in;
  cursor: pointer;
}
.contact-wrapper .bottom-section i:hover {
  text-shadow: none;
}

.login {
  transform: translate(0%, 40%);
  padding-bottom: 4rem;
  box-shadow: 0px 0px 10px 0px rgba(58, 55, 55, 0.164);
}
.login p {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
  background-color: var(--primary-cl);
  width: 100%;
  color: white;
  padding: 1.5rem;
}

@media screen and (max-width: 600px) {
  .contact-wrapper {
    width: 90%;
  }
  .contact-wrapper .middle-section form input,
  .contact-wrapper .middle-section form textarea {
    width: 100%;
  }
  .contact-wrapper .bottom-section .contact-icons {
    gap: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .contact-wrapper {
    width: 90%;
  }
  .contact-wrapper .bottom-section {
    flex-direction: column;
    gap: 2rem;
  }
  .contact-wrapper .bottom-section .contact-icons {
    gap: 1rem;
    gap: 4rem;
  }
}
.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(0%, 50%);
}
.error p {
  font-size: var(--fs-md);
  font-weight: 800;
  font-family: var(--ff-primary);
  letter-spacing: 0.1rem;
  color: var(--primary-cl);
  text-align: center;
}

.markdown {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  min-height: 100vh;
  position: relative;
  top: 0;
  width: 100%;
  gap: 1rem;
}
.markdown .textarea-conatiner {
  width: 520px;
  border: 1px solid;
  background-color: var(--primary-cl);
  padding: 0rem 1rem;
  height: 100%;
}
.markdown .textarea-conatiner form {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.markdown .textarea-conatiner label {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1rem;
}
.markdown .textarea-conatiner #title {
  width: 500px;
  height: 5rem;
  font-size: 2rem;
}
.markdown .textarea-conatiner #body,
.markdown .textarea-conatiner #summary {
  width: 500px;
  resize: none;
  height: 100%;
  font-size: 2rem;
  outline: none;
  border-left: 0.6rem;
}
.markdown .textarea-conatiner button {
  padding: 1rem 3rem;
  font-size: 1.8rem;
  background-color: rgb(8, 172, 217);
  border: none;
  color: white;
  border-radius: 0.4rem;
  margin-top: 1rem;
  margin-right: 3rem;
  cursor: pointer;
}
.markdown #body {
  min-height: 100vh;
}
.markdown #summary {
  min-height: 10rem;
}
.markdown .show-markdown {
  flex-grow: 1;
  height: 100%;
  font-size: 1.7rem;
}

.main-blog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-top: 3rem;
  background-color: var(--primary-cl);
}

.blog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
}
.blog-header p,
.blog-header a {
  font-size: 3rem;
  font-family: var(--ff-primary);
  color: white;
}
.blog-header a {
  font-size: var(--fs-xs);
  text-decoration: none;
}

.blog-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  gap: 3rem;
  height: 400px;
  background-color: var(--primary-cl);
  color: white;
  font-family: var(--ff-primary);
  text-align: center;
  font-size: var(--fs-sm);
  font-family: var(--ff-secondary);
  color: var(--text-cl);
  text-align: center;
  line-height: var(--lh-md);
  color: white;
}
@media screen and (max-width: 800px) {
  .blog-intro h1 {
    line-height: var(--lh-lg);
    font-size: var(--fs-md);
  }
}

.blog-list-container {
  background-color: var(--main-bg);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.blog-list-container .blog-list {
  margin-top: 2rem;
  padding: 3rem 2rem;
  cursor: pointer;
  box-shadow: 0px 6px 17px 1px var(--shadow-cl);
  border-radius: 0.5rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
}
.blog-list-container .blog-list .icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 1rem;
  gap: 2rem;
  font-size: var(--fs-sm);
}
.blog-list-container .blog-list .icons-container .red {
  color: red;
}
.blog-list-container .blog-list .icons-container .green {
  color: green;
}
.blog-list-container .blog-list .title {
  text-transform: capitalize;
  font-size: 3rem;
  font-family: var(--ff-primary);
  color: var(--text-cl);
}
.blog-list-container .blog-list .body {
  font-size: 2rem;
  font-family: var(--ff-secondary);
  color: var(--text-cl);
}
.blog-list-container .blog-list div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 800px) {
  .blog-list-container .blog-list {
    padding: 2rem 1rem;
    width: 95% !important;
  }
}
@media screen and (max-width: 600px) {
  .blog-list-container .blog-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .blog-list-container .blog-list div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .blog-list-container .blog-list div .title {
    font-size: var(--fs-sm);
    text-align: center;
  }
  .blog-list-container .blog-list div .body {
    font-size: var(--fs-xs);
    text-align: center;
  }
}
.custom {
  border: 2px solid black !important;
  color: white !important;
  font-size: 1.7rem !important;
  width: 100% !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.giscus {
  width: 90%;
  margin: auto;
}

.comment-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.comment-section textarea {
  width: 60%;
  margin: auto;
  height: 10rem;
}
.comment-section button {
  padding: 1rem 3rem;
  font-size: 1.9rem;
  background-color: rgb(40, 140, 206);
  border: none;
  color: white;
  cursor: pointer;
}

.specific-blog-container {
  max-width: 1440px;
  margin: auto;
}

/* header */
.specific-blog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--primary-cl);
  color: white;
  padding: 2rem 3rem;
}
.specific-blog-header p {
  font-size: 3.2rem;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.specific-blog-header div ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 3rem;
  font-size: var(--fs-sm);
  list-style: none;
}
.specific-blog-header div ul a {
  text-decoration: none;
  color: white;
}

/* specific blog */
.specific-blog {
  display: flex;
  align-items: inherit;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: auto;
  font-size: 2.3rem;
}
.specific-blog p {
  font-size: 2.3rem !important;
  line-height: var(--lh-lg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.specific-blog h1 {
  font-size: 4.5rem;
}
.specific-blog h2 {
  font-size: 3.9rem;
}
.specific-blog h3 {
  font-size: 2.6rem;
}
.specific-blog img {
  align-self: center;
}

/* Icons container */
.specific-blog-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  font-size: 3rem;
  margin-top: 3rem;
  width: 50px;
  padding: 1rem 6rem;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 10px var(--shadow-cl);
  margin: auto;
}
.specific-blog-icons-container i {
  color: var(--primary-cl);
  cursor: pointer;
}
.specific-blog-icons-container .heat-red {
  color: rgb(224, 10, 10);
}
.specific-blog-icons-container p {
  margin-right: 1rem;
}

/* About author */
.about-author {
  width: 70%;
  margin: auto;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}
.about-author .col-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 2rem;
}
.about-author .col-1 .autho-img {
  width: 130px;
  height: 130px;
}
.about-author .col-1 .autho-img img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-author .col-1 .upper-text {
  font-family: var(--ff-primary);
  color: var(--primary-cl);
}
.about-author .col-1 .upper-text strong {
  font-size: 1.3rem;
  font-weight: 700;
}
.about-author .col-1 .upper-text p {
  font-size: 3rem;
}
.about-author .col-2 {
  font-size: 1.9rem;
  text-align: center;
  margin-top: 1rem;
  color: var(--primary-cl);
  font-family: var(--ff-secondary);
  line-height: var(--lh-md);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  color: var(--primary-cl);
  font-family: var(--ff-primary);
  top: 50%;
  gap: 2rem;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 10px 0px var(--shadow-cl);
  padding: 5rem;
  font-size: 3rem;
  background-color: var(--main-bg);
  left: 35%;
  transform-origin: center;
  animation-name: transformScale;
  animation-duration: 0.5s;
  transform-origin: center;
  opacity: 1;
}
.modal .fa-twitter,
.modal .fa-linkedin {
  color: rgb(38, 38, 231);
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.modal .fa-twitter:hover,
.modal .fa-linkedin:hover {
  transform: scale(1.2);
}

@keyframes transformScale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.specific-blog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  width: 100%;
  background-color: var(--primary-cl);
  padding: 3rem 2rem;
  margin-top: 3rem;
}
.specific-blog-footer p {
  font-size: 2rem;
  font-family: var(--ff-primary);
  color: white;
}
.specific-blog-footer .logo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
}
.specific-blog-footer .logo small {
  font-size: 1rem;
  font-family: var(--ff-primary);
  color: white;
}
.specific-blog-footer .col-2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 5rem;
}
.specific-blog-footer .privacy-policy {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 2.4rem;
  color: white;
}
.specific-blog-footer .privacy-policy small {
  cursor: pointer;
}
.specific-blog-footer .contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}
.specific-blog-footer .contact i {
  font-size: 3rem;
  margin-right: 2rem;
  color: white;
}

@media screen and (max-width: 780px) {
  .specific-blog img {
    width: 90%;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-bg: #ecf0f3;
  --btn-bg: #444d54;
  --primary-cl: #444d54;
  --text-cl: #2e2d2d;
  --shadow-cl: #7c859c;
  --fs-xl: 7.5rem;
  --fs-lg: 5.5rem;
  --fs-md: 5rem;
  --fs-sm: 2rem;
  --fs-xs: 1.6rem;
  --lh-lg: 3.5rem;
  --lh-md: 3rem;
  --lh-xs: 0.1rem;
  --ff-primary: "Merriweather", serif;
  --ff-secondary: "Work Sans", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: var(--main-bg);
  overflow-x: hidden;
  margin-bottom: 3rem;
}

.main-wrapper {
  max-width: 1440px;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 1250px) {
  html {
    font-size: 52.5%;
  }
}
@media screen and (max-width: 1050px) {
  :root {
    --fs-xl: 6.5rem;
    --fs-lg: 5rem;
  }
}
@media screen and (max-width: 880px) {
  html {
    font-size: 47.5%;
  }
}
@media screen and (max-width: 880px) {
  :root {
    --fs-xl: 5rem;
    --fs-lg: 4rem;
    --fs-md: 3.2rem;
    --fs-sm: 1.8rem;
    --fs-xs: 1.6rem;
    --lh-lg: 3.5rem;
    --lh-md: 3rem;
    --lh-xs: 0.1rem;
  }
}